import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';

const Error = () => {
  let lang = '';
  if (window.location.href.includes('/fr')) lang = 'fr';
  if (window.location.href.includes('/eng')) lang = 'eng';

  if (!lang) {
    if (window.location.origin.includes('/fr')) lang = 'fr';
    else lang = 'eng';
  }

  const [errorText, buttonText] =
    lang === 'fr'
      ? ["Cette page n'existe pas.", "REVENIR À L'ACCUEIL"]
      : ['This page does not exist.', 'GO BACK'];

  return (
    <Layout>
      <SEO title="Erreur || Inumeris" />
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <div className="error-inner">
                <h1>404</h1>
                <h2 className="title">{errorText}</h2>

                <div className="view-more-button">
                  <a className="btn-default" href="/">
                    {buttonText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
