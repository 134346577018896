import React from 'react';
import SEO from '../../common/SEO';
import Header from '../../common/header/Header';
import Footer from '../../component/Footer';
import SectionTitle from '../../elements/sectionTitle/SectionTitle';
import ContactOne from '../../component/contact/ContactOne';

const Contact = () => {
  return (
    <>
      <SEO title="Contact || Inumeris" />
      <main className="page-wrapper">
        <Header
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        <div className="main-content">
          {/* Start Contact Area  */}
          <div className="rwt-contact-area rn-section-gap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <SectionTitle
                    textAlign="text-center"
                    radiusRounded=""
                    subtitle="Let's work together"
                    title="Start a project, set up a meeting, apply or just say hi"
                    description=""
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
        <Footer />
      </main>
    </>
  );
};
export default Contact;
