import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';

const Error = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <Layout>
      <SEO title="RGPD || Inumeris" />
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <h1>Données personnelles – Inumeris</h1>
              <h5>
                Les données d'utilisateurs du site www.inumeris.fr engage un
                traitement de données personnelles géré par Inumeris.
              </h5>
              <h2>Objet du traitement de données</h2>

              <h3>Finalités</h3>
              <p>
                Le traitement a pour objet de{' '}
                <strong>
                  gérer les services proposés aux personnes (utilisateurs) du
                  site « Inumeris »
                </strong>
                .<br />
                Il permet à Inumeris :
              </p>
              <ul>
                <li>
                  de permettre la navigation des utilisateurs du site et de
                  suivre la progression de cette navigation
                </li>
                <li>
                  de mettre à disposition des utilisateurs un formulaire de
                  contact et d’en assurer le bon fonctionnement
                </li>
                <li> de communiquer avec les utilisateurs</li>
                <li>
                  de produire des statistiques sur l’utilisation du service
                </li>
              </ul>

              <h3>Base légale</h3>
              <p>
                <a
                  href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre2#Article6"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  Article 6
                </a>{' '}
                (1) a du règlement général sur la protection des données - RGPD
                <br />
                Ces traitements de données relèvent du consentement de
                l'utilisateur au traitement de ses données à caractère personnel
                pour une ou plusieurs finalités spécifiques.
                <br />
                <br />
                <a
                  href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre2#Article6"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  Article 6
                </a>{' '}
                (1) f du règlement général sur la protection des données - RGPD
                <br />
                Ces traitements de données relèvent de la nécessité du
                traitement aux fins des intérêts légitimes poursuivis par le
                responsable du traitement. À savoir l'intérêt légitime de
                pouvoir répondre aux attentes des utilisateurs du site et
                d'assurer le bon fonctionnement du site.
                <br />
                <br />
                Ces traitements sont exercés en application du règlement général
                sur la protection des données et de la loi Informatique et
                Libertés.
              </p>

              <h2>Données traitées</h2>
              <h3>Catégories de données traitées</h3>
              <ul>
                <li>
                  Données d’identification des utilisateurs (nom, prénom et
                  adresse électronique)
                </li>
                <li>
                  Données d’utilisation du service (adresse IP ; navigateur
                  utilisé ; système d’exploitation ; dates, horaires et temps de
                  connexion sur la plateforme ; données d’authentification
                  collectées au moyen d’un cookie de session
                </li>
                <li> Echanges entre la Inumeris et les utilisateurs</li>
              </ul>

              <h3>Source des données</h3>
              <p>
                Ces informations sont recueillies auprès de l'utilisateur du
                site Inumeris.
              </p>

              <h3>Caractère obligatoire du recueil des données</h3>
              <p>
                Le site Inumeris prévoit le recueil obligatoire des données qui
                sont nécessaires à la gestion de ce service.
              </p>

              <h3>Prise de décision automatisée</h3>
              <p>
                Le traitement ne prévoit pas de{' '}
                <a
                  href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3#Article22"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  prise de décision automatisée
                </a>
                .
              </p>

              <h2>Personnes concernées</h2>
              <p>Le traitement de données concerne :</p>
              <ul>
                <li>
                  les personnes qui souhaitent s’inscrire au site Inumeris
                </li>
                <li>
                  les personnels de la Inumeris et du prestataire en charge de
                  la gestion du site Inumeris
                </li>
              </ul>

              <h2>Destinataires des données</h2>
              <h3>Catégories de destinataires</h3>
              <p>
                En fonction de leurs besoins respectifs, sont destinataires de
                tout ou partie des données les personnels de la Inumeris et du
                prestataire en charge de la gestion du site Inumeris.
              </p>

              <h3>Transferts des données hors UE</h3>
              <p>
                Aucun transfert de données hors de l'Union européenne n'est
                réalisé.
              </p>

              <h2>Durée de conservation des données</h2>
              <p>
                Les données à caractère personnel collectées sont conservées au
                plus tard 1 an à compter de leur collecte.
              </p>

              <h2>Sécurité</h2>
              <p>
                Les mesures de sécurité sont mises en œuvre conformément à la
                politique de sécurité des systèmes d’information (PSSI) de la
                Inumeris, issue de la{' '}
                <a
                  href="https://www.ssi.gouv.fr/entreprise/reglementation/protection-des-systemes-dinformations/la-politique-de-securite-des-systemes-dinformation-de-letat-pssie/"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  PSSI de l’Etat
                </a>
                .
              </p>

              <h2>Vos droits sur les données vous concernant</h2>
              <p>
                Vous pouvez accéder et obtenir copie des données vous
                concernant, vous opposer au traitement de ces données, les faire
                rectifier ou les faire effacer. Vous disposez également d'un
                droit à la limitation du traitement de vos données.
              </p>

              <a
                href="https://www.cnil.fr/fr/comprendre-vos-droits"
                target="_blank"
                rel="noreferrer"
                class="rgb-text"
              >
                Comprendre vos droits informatique et libertés
              </a>
              <br />
              <br />

              <h3>Exercer ses droits</h3>
              <p>
                Le délégué à la protection des données (DPO) de la Inumeris est
                votre interlocuteur pour toute demande d'exercice de vos droits
                sur ce traitement.
              </p>
              <ul>
                <li>
                  Contacter le DPO{' '}
                  <a
                    href={`/${lang}/contact`}
                    target="_blank"
                    rel="noreferrer"
                    class="rgb-text"
                  >
                    par voie électronique
                  </a>
                </li>
                <br />
                <li>
                  Contacter le DPO par courrier postal
                  <br />
                  Le délégué à la protection des données
                  <br />
                  Inumeris
                  <br />
                  47 bis avenue Danielle Casanova
                  <br />
                  94200 IVRY-SUR-SEINE PARIS
                  <br />
                </li>
              </ul>

              <h3>Réclamation (plainte) auprès de la CNIL</h3>
              <p>
                Si vous estimez, après nous avoir contactés, que vos droits sur
                vos données ne sont pas respectés, vous pouvez{' '}
                <a
                  href="https://www.cnil.fr/fr/webform/adresser-une-plainte"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  adresser une réclamation (plainte) à la CNIL
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
