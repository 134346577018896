import React from 'react';
import ContactForm from './ContactForm';
import GoogleMapStyle from './GoogleMapStyle';
import { FiHeadphones, FiMail, FiMapPin } from 'react-icons/fi';

const contentFr = {
  byPhone: 'Par téléphone',
  byEmail: 'Par email',
  inPerson: 'En personne'
};

const contentEng = {
  byPhone: 'By phone',
  byEmail: 'By email',
  inPerson: 'In person'
};

const ContactOne = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  const content = lang === 'fr' ? contentFr : contentEng;

  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <div className="rn-contact-address mt_dec--30">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">{content.byPhone}</h4>
                    <p>
                      <a href="tel:+33148682741">+33 1 48 68 27 41</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">{content.byEmail}</h4>
                    <p>
                      <a href="mailto:contact@inumeris.fr">
                        contact@inumeris.fr
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">{content.inPerson}</h4>
                    <p>
                      <a
                        href="https://www.google.com/maps/place/94200+Ivry-sur-Seine/@48.8124923,2.3522177,13z/data=!3m1!4b1!4m5!3m4!1s0x47e673b5023235ad:0x5dc8118d7cbf704f!8m2!3d48.813055!4d2.38822"
                        target="_blank"
                        rel="noreferrer"
                      >
                        94200, Ivry-sur-Seine, Paris
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt--40 row--15">
        <div className="col-lg-7">
          <ContactForm formStyle="contact-form-1" lang={lang} />
        </div>
        <div className="col-lg-5 mt_md--30 mt_sm--30">
          <GoogleMapStyle />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
