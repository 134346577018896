import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

const contentFr = {
  text: 'On vous accompagne sur tous vos besoins digitaux.',
  buttonText: 'VOIR TOUTES NOS COMPÉTENCES'
};

const contentEng = {
  text: 'We assist you with all your digital needs.',
  buttonText: 'SEE ALL OUR CAPABILITIES'
};

const CalltoActionServices = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';
  const content = lang === 'fr' ? contentFr : contentEng;

  const urlname = lang === 'fr' ? '/fr/competences' : '/eng/capabilities';

  return (
    <div className="container" style={{ paddingBottom: '120px' }}>
      <div className="row row--0 align-items-center ">
        <div className="col-lg-12">
          <div className="inner">
            <div className="content text-center">
              <h6 className="subtitle">{content.text}</h6>
              <div className="call-to-btn text-center mt--30">
                <a className="btn-default btn-icon" href={urlname}>
                  {content.buttonText}
                  <i className="icon">
                    <FiArrowRight />
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionServices;
