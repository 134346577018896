import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';

const Error = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <Layout>
      <SEO title="GDPR || Inumeris" />
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <h1>Personal data – Inumeris</h1>
              <h5>
                The data of users of the site www.inumeris.fr engages a
                processing of personal data managed by Inumeris.
              </h5>
              <h2>Object of data processing</h2>

              <h3>Purposes</h3>
              <p>
                The purpose of the processing is to
                <strong>
                  manage the services offered to people (users) of the
                  “Inumeris” website
                </strong>
                .<br />
                It allows Inumeris:
              </p>
              <ul>
                <li>
                  to allow the navigation of users of the site and to follow the
                  progress of this navigation
                </li>
                <li>
                  to provide users with a form for contact and to ensure its
                  proper functioning
                </li>
                <li>to communicate with users</li>
                <li>to produce statistics on the use of the service</li>
              </ul>

              <h3>Legal basis</h3>
              <p>
                <a
                  href="https://www.cnil.fr/fr/european-data-protection-regulation/chapter2#Article6"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  Section 6
                </a>
                (1) a of the General Data Protection Regulation - GDPR
                <br />
                This data processing is subject to the consent of the user to
                the processing of his personal data for one or more specific
                purposes.
                <br />
                <br />
                <a
                  href="https://www.cnil.fr/fr/european-data-protection-regulation/chapter2#Article6"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  Section 6
                </a>
                (1) f of the General Data Protection Regulation - GDPR
                <br />
                This data processing is based on the necessity of the processing
                for the purposes of the legitimate interests pursued by the
                controller. Namely the legitimate interest of to be able to meet
                the expectations of users of the site and to ensure the proper
                functioning of the site.
                <br />
                <br />
                This processing is carried out in application of the general
                regulations on data protection and the IT law and Freedoms.
              </p>

              <h2>Data processed</h2>
              <h3>Categories of processed data</h3>
              <ul>
                <li>
                  User identification data (surname, first name and email
                  address)
                </li>
                <li>
                  Service usage data (IP address; browser used ; operating
                  system ; dates, times and times of connection to the platform;
                  authentication data collected using a session cookie
                </li>
                <li> Exchanges between Inumeris and users</li>
              </ul>

              <h3>Source of data</h3>
              <p>
                This information is collected from the user of the Inumeris
                website.
              </p>

              <h3>Mandatory nature of data collection</h3>
              <p>
                The Inumeris site provides for the compulsory collection of data
                which are necessary for the management of this service.
              </p>

              <h3>Automated decision making</h3>
              <p>
                The processing does not provide
                <a
                  href="https://www.cnil.fr/fr/european-data-protection-regulation/chapter3#Article22"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  automated decision making
                </a>
                .
              </p>

              <h2>Persons concerned</h2>
              <p>Data processing concerns:</p>
              <ul>
                <li>people who wish to register on the Inumeris site</li>
                <li>
                  the staff of Inumeris and the service provider in charge of
                  management of the Inumeris site
                </li>
              </ul>

              <h2>Data recipients</h2>
              <h3>Recipient categories</h3>
              <p>
                Depending on their respective needs, are recipients of all or
                part of the data the personnel of Inumeris and the service
                provider in charge of managing the Inumeris site.
              </p>

              <h3>Transfers of data outside the EU</h3>
              <p>No transfer of data outside the European Union is realized.</p>

              <h2>Data retention period</h2>
              <p>
                The personal data collected is kept at the later than 1 year
                from their collection.
              </p>

              <h2>Security</h2>
              <p>
                Security measures are implemented in accordance with the
                information systems security policy (ISSP) of the Inumeris, from
                the
                <a
                  href="https://www.ssi.gouv.fr/entreprise/reglementation/protection-des-systemes-dinformations/la-politique-de-securite-des-systemes-dinformation-de-letat-pssie/"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  State PSSI
                </a>
                .
              </p>

              <h2>Your rights to data concerning you</h2>
              <p>
                You can access and obtain a copy of the data you concerning, to
                object to the processing of this data, to have it rectify or
                have them erased. You also have a right to limit the processing
                of your data.
              </p>

              <a
                href="https://www.cnil.fr/fr/understanding-your-rights"
                target="_blank"
                rel="noreferrer"
                class="rgb-text"
              >
                Understand your computer rights and freedoms
              </a>
              <br />
              <br />

              <h3>Exercise your rights</h3>
              <p>
                The Data Protection Officer (DPO) of Inumeris is your contact
                for any request to exercise your rights on this treatment.
              </p>
              <ul>
                <li>
                  Contact the DPO
                  <a
                    href={`/${lang}/contact`}
                    target="_blank"
                    rel="noreferrer"
                    class="rgb-text"
                  >
                    electronically
                  </a>
                </li>
                <br />
                <li>
                  Contact the DPO by post
                  <br />
                  The data protection officer
                  <br />
                  Inumeris
                  <br />
                  47 bis avenue Danielle Casanova
                  <br />
                  94200 IVRY-SUR-SEINE PARIS
                  <br />
                </li>
              </ul>

              <h3>Complaint (complaint) to the CNIL</h3>
              <p>
                If you believe, after contacting us, that your rights to your
                data is not respected, you can
                <a
                  href="https://www.cnil.fr/fr/webform/address-une-plainte"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  send a claim (complaint) to the CNIL
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
