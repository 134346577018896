import React from 'react';
import Footer from '../component/Footer';
import Header from '../common/header/Header';
import SEO from '../common/SEO';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Separator from '../elements/separator/Separator';
import Valeurs from '../component/Valeurs';
import Experts from '../component/Experts';
import Equipe from '../component/Equipe';
import CallActionContact from '../component/CallActionContact';

const AboutUs = (props) => {
  return (
    <>
      <SEO title="Agence || Inumeris" />
      <main className="page-wrapper">
        <Header
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">Qui nous sommes</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Inumeris est une équipe unie constituée de membres créatifs et
                  curieux dont le siège est basé à Paris.
                  <br />
                  <br /> Nous sommes ouverts, collaboratifs et mettons à profit
                  notre expérience collective ainsi que notre expertise
                  technique afin de créer des solutions digitales sur tout type
                  de support.
                  <br />
                  <br /> Nous embrassons les nouveaux défis à coeur ouvert, avec
                  un désir d'apprendre et une passion pour la recherche
                  collaborative de solutions digitales.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=" Cultiver le numérique sans être des robots"
                  title="Nos Valeurs"
                />
              </div>
            </div>
            <Valeurs
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="des amoureux de la tech"
                  title="Équipe Permanente"
                  description="Vous ne parlez pas le numérique ? Parfait. Nous on veut vous parler business."
                />
              </div>
            </div>
            <Equipe
              column="col-lg-6 col-md-6 col-12 mt--10"
              teamStyle="team-style-default style-two"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--15">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="un généraliste < un expert "
                  title="Nos Experts"
                  description="Chez nous chaque corps de métier à son expert dédié."
                />
              </div>
            </div>
            <Experts
              column="col-lg-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-two"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Call To Action Area  */}
        <div
          className="rwt-callto-action-area"
          style={{ marginBottom: '64px' }}
        >
          <div className="wrapper">
            <CallActionContact />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Footer />
      </main>
    </>
  );
};

export default AboutUs;
