import React from 'react';
import Header from '../../common/header/Header';
import SEO from '../../common/SEO';
import Footer from '../../component/Footer';

import CalltoAction from '../../component/CallActionContact';
import CalltoActionServices from '../../component/CalltoActionServices';
import Services from '../../component/Services';
import Timeline from '../../component/Timeline';
import SectionTitle from '../../elements/sectionTitle/SectionTitle';

const DigitalAgency = (props) => {
  return (
    <>
      <SEO title="Inumeris" />
      <main className="page-wrapper">
        <Header btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Slider Area  */}
        <div
          className="slider-area slider-style-1 variation-default height-850 bg_image"
          data-black-overlay="7"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/img/home-background.jpg)`,
            backgroundSize: 'cover',
            height: '100vh'
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <h1 className="title display-one">WELCOME TO INUMERIS</h1>
                  <p className="description">
                    We help brands and businesses win a competitive advantage
                    through blockchain and technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Slider Area  */}
        <div className="slider-area slider-style-6 shape-right height-750">
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h4 className="title color-white">
                    Strategy Driven Development.
                  </h4>
                  <p className="description color-white">
                    We help businesses build, launch new products as well as
                    build lasting relationships with their communities.
                  </p>
                </div>
              </div>

              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/business_with_technology.jpg`}
                    alt="Banner Images"
                  />
                </div>
              </div>
              <div className="shape-image">
                <img
                  src={`${process.env.PUBLIC_URL}/img/white-shape.svg`}
                  alt="Banner Images"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <div className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="made in France"
                  title="The Inumeris process"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10 offset-lg-1 mt--50">
                <Timeline classVar="" />
              </div>
            </div>
          </div>
        </div>

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="On today's menu"
                  title="What we do yields results."
                  description="Years of experience, talents from many fields, a long history of innovation and relevant deliverables."
                />
              </div>
            </div>
            <Services serviceStyle="gallery-style" textAlign="text-start" />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-callto-action-area ">
          <div className="wrapper">
            <CalltoActionServices />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Call To Action Area  */}
        <div
          className="rwt-callto-action-area"
          style={{ marginBottom: '64px' }}
        >
          <div className="wrapper">
            <CalltoAction />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Footer />
      </main>
    </>
  );
};
export default DigitalAgency;
