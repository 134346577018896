import React from 'react';
import SEO from '../common/SEO';
import Layout from '../common/Layout';

const Error = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <Layout>
      <SEO title="Mentions Légales || Inumeris" />
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <h1>Mentions légales</h1>
              <h5>Le site www.inumeris.fr est édité par la Inumeris</h5>

              <h2>Editeur</h2>
              <p>
                La Inumeris
                <br /> <br /> 47 bis avenue Danielle Casanova
                <br />
                94200 IVRY-SUR-SEINE PARIS
                <br />
                Téléphone : +33 (0)1 48 68 27 41
                <br />
                <br />
                Directeur de la publication : Sophie JARDIN, DPO
                <br />
              </p>

              <h2>Hébergement</h2>
              <p>
                L’hébergement de ce site internet est assuré par OVH
                <br />
                Société par actions simplifiée à associé unique inscrite au
                R.C.S. de Lille Métropole sous le numéro 424 761 419 00045
                <br />
                Dont le siège est situé
                <br />
                2 rue kellermann
                <br />
                59100 Roubaix
                <br />
              </p>

              <h2>Utilisation des marques de Inumeris</h2>
              <p>
                Les marques verbales et visuelles (logos) Inumeris, sont
                protégées.
              </p>
              <p>
                Leur utilisation sans autorisation écrite de la Inumeris, sur
                tout support, à des fins de valorisation de produits ou de
                services, notamment à des fins commerciales, est interdite sous
                peine de poursuites pénales et civiles.
              </p>
              <p>
                Elle est admise à des fins strictement pédagogiques
                (présentation des actions de Inumeris, etc.).
              </p>
              <p>
                Pour toute autre réutilisation,{' '}
                <a
                  href={`/${lang}/contact`}
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  nous contacter
                </a>
                .
              </p>

              <h2>Réutilisation des contenus</h2>
              <p>
                L’ensemble des contenus disponibles sur www.inumeris.fr est sous
                licence Creative Commons Attribution – Pas d’utilisation
                commerciale – Pas de modification 3.0 France (CC BY-NC-ND 3.0
                Fr).
              </p>
              <p>
                <a
                  href="https://creativecommons.org/licenses/by-nc-nd/3.0/deed.fr"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  CC BY-ND-NC 3.0 FR
                </a>
                <br />
              </p>
              <ul>
                <li>
                  <strong>Attribution</strong> :<br /> vous devez créditer les
                  contenus, intégrer un lien vers la licence et préciser la date
                  à laquelle le contenu a été extrait du site. Vous devez
                  indiquer ces informations par tous les moyens raisonnables,
                  sans toutefois suggérer directement ou indirectement que
                  Inumeris vous soutient ou soutient la façon dont vous avez
                  utilisé ses contenus.
                </li>
                <br />
                <li>
                  <strong>Pas d’utilisation commerciale</strong> :<br /> vous
                  n'êtes pas autorisé à faire un usage commercial de ces
                  contenus, tout ou partie du matériel les composant.
                </li>
                <br />
                <li>
                  <strong>Pas de modifications</strong>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
