import React, { useState, createRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const contentFr = {
  fullname: 'Nom Complet *',
  email: 'Adresse Email *',
  phone: 'Téléphone',
  title: 'Sujet *',
  message: 'Parlez nous de votre projet *',
  buttonText: 'ENVOYER',
  buttonTextSuccess: 'ENVOYÉ ✔️'
};

const contentEng = {
  fullname: 'Full Name *',
  email: 'Email Address *',
  phone: 'Phone Number',
  title: 'Subject *',
  message: 'Tell us about your project *',
  buttonText: 'SEND',
  buttonTextSuccess: 'SENT ✔️'
};

const Result = ({ language, result }) => {
  const innerContent =
    language === 'fr'
      ? 'Votre message à bien été envoyé. Nous vous recontactons rapidement.'
      : 'Your message has been sent. We will get back to you shortly.';
  return (
    <p className="success-message" style={result ? null : { display: 'none' }}>
      {innerContent}
    </p>
  );
};

function ContactForm({ props, formStyle, lang }) {
  const [result, showresult] = useState(false);

  const content = lang === 'fr' ? contentFr : contentEng;

  const [form, setForm] = useState({
    fullname: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
    recaptcha: ''
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const field = e.target.name;
    const newState = { ...form, [field]: value };
    setForm(newState);
  };

  const recaptchaRef = createRef();

  const handleRecaptcha = (value) => {
    const newState = { ...form, recaptcha: value };
    setForm(newState);
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const result = await fetch(
        `${process.env.PUBLIC_URL}/.netlify/functions/fn_contact`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: JSON.stringify(form)
        }
      );

      recaptchaRef.current.reset();

      if (result.status === 200) {
        e.target.reset();
        showresult(true);

        setTimeout(() => {
          showresult(false);
        }, 10000);
      } else {
        throw Error(
          lang === 'fr'
            ? "Désolé, nous n'avons pas pu livrer votre email. Veuillez réessayer plus tard."
            : 'Sorry we could not deliver your email. Please try again later.'
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  return (
    <form className={`${formStyle}`} action="" onSubmit={handleSubmit}>
      <div className="form-group">
        <input
          type="text"
          name="fullname"
          placeholder={content.fullname}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <input
          type="email"
          name="email"
          placeholder={content.email}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <input
          type="tel"
          name="phone"
          placeholder={content.phone}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <input
          type="text"
          name="subject"
          placeholder={content.title}
          onChange={handleChange}
          required
        />
      </div>

      <div className="form-group">
        <textarea
          name="message"
          placeholder={content.message}
          onChange={handleChange}
          required
        ></textarea>
      </div>

      <div
        className="form-group"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          justifyContent: 'space-around'
        }}
      >
        <ReCAPTCHA
          sitekey="6LdK9PYhAAAAAPZuKpiP1qILdK-lf8MiCrGtuuZN"
          theme="dark"
          name="recaptcha"
          style={{ marginTop: '2rem' }}
          ref={recaptchaRef}
          hl={lang === 'fr' ? 'fr' : 'en'}
          onChange={handleRecaptcha}
        />
        <button
          className="btn-default btn-large"
          style={{ marginLeft: '2rem', marginTop: '2rem' }}
        >
          {result ? content.buttonTextSuccess : content.buttonText}
        </button>
      </div>

      <div>
        <Result language={lang} result={result} />{' '}
      </div>
    </form>
  );
}
export default ContactForm;
