import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';

const Error = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <Layout>
      <SEO title="Terms & Conditions || Inumeris" />
      <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
        <div className="container">
          <div className="row align-item-center">
            <div className="col-lg-12">
              <h1>Legal notice</h1>
              <h5>The website www.inumeris.fr is published by Inumeris</h5>

              <h2>Editor</h2>
              <p>
                The Inumeris
                <br /> <br /> 47 bis avenue Danielle Casanova
                <br />
                94200 IVRY-SUR-SEINE PARIS
                <br />
                Telephone: +33 (0)1 48 68 27 41
                <br />
                <br />
                Director of publication: Sophie JARDIN, DPO
                <br />
              </p>

              <h2>Accommodation</h2>
              <p>
                This website is hosted by OVH
                <br />
                Simplified joint-stock company with a single partner registered
                in the RCS of Lille Métropole under number 424 761 419 00045
                <br />
                Whose head office is located
                <br />
                2 rue kellermann
                <br />
                59100 Roubaix
                <br />
              </p>

              <h2>Use of Inumeris Trademarks</h2>
              <p>
                The verbal and visual brands (logos) Inumeris are protected.
              </p>
              <p>
                Their use without written authorization from Inumeris, on any
                medium, for the purpose of promoting products or services, in
                particular for commercial purposes, is prohibited under
                penalties for criminal and civil prosecution.
              </p>
              <p>
                It is admitted for strictly educational purposes. (presentation
                of Inumeris shares, etc.).
              </p>
              <p>
                For any other reuse,{' '}
                <a
                  href={`/${lang}/contact`}
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  Contact us
                </a>
                .
              </p>

              <h2>Reuse of content</h2>
              <p>
                All content available on www.inumeris.fr is under Creative
                Commons Attribution License – No Use commercial – No
                modification 3.0 France (CC BY-NC-ND 3.0 Fr).
              </p>
              <p>
                <a
                  href="https://creativecommons.org/licenses/by-nc-nd/3.0/deed.fr"
                  target="_blank"
                  rel="noreferrer"
                  class="rgb-text"
                >
                  CC BY-ND-NC 3.0 EN
                </a>
                <br />
              </p>
              <ul>
                <li>
                  <strong>Attribution</strong>:<br /> you must credit the
                  content, include a link to the license and specify the date at
                  which the content was extracted from the site. You have to
                  provide this information by all reasonable means, without,
                  however, directly or indirectly suggesting that Inumeris
                  supports you or supports the way you have used its contents.
                </li>
                <br />
                <li>
                  <strong>No Commercial Use</strong>:<br /> you are not
                  authorized to make commercial use of these contents, all or
                  part of the material composing them.
                </li>
                <br />
                <li>
                  <strong>No changes</strong>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
