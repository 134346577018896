import React from 'react';
import { AiFillHeart } from 'react-icons/ai';
import { BsLadder } from 'react-icons/bs';
import { FaHandshake } from 'react-icons/fa';
import { GiAnt, GiCutDiamond, GiPlantSeed, GiStairs } from 'react-icons/gi';
import { IoIosRocket, IoMdCompass } from 'react-icons/io';

const serviceList = [
  {
    icon: <AiFillHeart />,
    title: '01',
    description: 'Adopte une bienveillance spontanée'
  },
  {
    icon: <IoIosRocket />,
    title: '02',
    description: 'Embrasse et conduit le changement'
  },
  {
    icon: <IoMdCompass />,
    title: '03',
    description: "Sois aventureux, créatif et ouvert d'esprit"
  },
  {
    icon: <GiStairs />,
    title: '04',
    description: 'Poursuis ton développement et ton apprentissage'
  },
  {
    icon: <FaHandshake />,
    title: '05',
    description:
      'Construis des relations ouvertes et honnêtes fondées sur la communication'
  },
  {
    icon: <GiPlantSeed />,
    title: '06',
    description: "Bâtis une communauté positive doté d'un esprit accueillant"
  },
  {
    icon: <GiCutDiamond />,
    title: '07',
    description: 'Fais plus avec moins'
  },
  {
    icon: <BsLadder />,
    title: '08',
    description: 'Construis des solutions et non des barrières'
  },
  {
    icon: <GiAnt />,
    title: '09',
    description: 'Sois humble'
  }
];

const serviceListEng = [
  {
    icon: <AiFillHeart />,
    title: '01',
    description: 'Practice random acts of kindness'
  },
  {
    icon: <IoIosRocket />,
    title: '02',
    description: 'Embrace and drive change'
  },
  {
    icon: <IoMdCompass />,
    title: '03',
    description: 'Be adventurous, creative, and open-minded'
  },
  {
    icon: <GiStairs />,
    title: '04',
    description: 'Pursue growth and learning'
  },
  {
    icon: <FaHandshake />,
    title: '05',
    description: 'Build open and honest relationships with communication'
  },
  {
    icon: <GiPlantSeed />,
    title: '06',
    description: 'Build a positive team and family spirit'
  },
  {
    icon: <GiCutDiamond />,
    title: '07',
    description: 'Do more with less'
  },
  {
    icon: <BsLadder />,
    title: '08',
    description: 'Build solutions, not barriers'
  },
  {
    icon: <GiAnt />,
    title: '09',
    description: 'Be humble'
  }
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <div className="row row--15 service-wrapper">
      {(lang === 'fr' ? serviceList : serviceListEng).map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <div
            className={`service ${serviceStyle} ${textAlign}`}
            style={{ height: '280px', backgroundColor: 'pink' }}
          >
            <div
              className="icon"
              style={{ color: i % 2 === 1 ? '#82c7a5' : '#0145ac' }}
            >
              {val.icon}
            </div>
            <div className="content">
              <h4 className="title w-600">{val.title}</h4>
              <p
                className="description b1  mb--0"
                dangerouslySetInnerHTML={{ __html: val.description }}
              ></p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
