import React from 'react';
import NewsletterOne from '../common/footer/NewsletterOne';
import ScrollTop from '../common/footer/ScrollTop';

const FooterFour = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  const [rgpd, legal, contact] =
    lang === 'fr' ? ['rgpd', 'legal', 'contact'] : ['gdpr', 'tc', 'contact'];

  const [rgpdText, legalText, contactText] =
    lang === 'fr'
      ? ['RGPD', 'Mentions Légales', 'Nous Contacter']
      : ['GDPR', 'Terms & Conditions', 'Contact Us'];

  return (
    <>
      <footer className="rn-footer footer-style-default no-border">
        <NewsletterOne
          newsletetrStyle="rn-newsletter-default"
          extraClass="border-top-bottom"
        />
        <div className="copyright-area copyright-style-one no-border">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="copyright-left">
                  <ul className="ft-menu link-hover">
                    <li>
                      <a href={`/${lang}/${rgpd}`}>{rgpdText}</a>
                    </li>
                    <li>
                      <a href={`/${lang}/${legal}`}>{legalText}</a>
                    </li>
                    <li>
                      <a href={`/${lang}/${contact}`}>{contactText}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                <div className="copyright-right text-center text-md-end">
                  <p className="copyright-text">
                    © Inumeris {new Date().getFullYear()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <ScrollTop />
    </>
  );
};

export default FooterFour;
