import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

const contentFr = {
  text: 'Prêt à travailler ensembles ?',
  buttonText: 'COMMENCER UN PROJET'
};
const contentEng = { text: 'Ready to talk ?', buttonText: 'START A PROJECT' };

const CalltoActionFive = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';
  const content = lang === 'fr' ? contentFr : contentEng;

  return (
    <div className="rn-callto-action clltoaction-style-default style-5">
      <div className="container">
        <div className="row row--0 align-items-center content-wrapper theme-shape">
          <div className="col-lg-12">
            <div className="inner">
              <div className="content text-center">
                <h2 className="title">{content.text}</h2>

                <div className="call-to-btn text-center">
                  <a className="btn-default btn-icon" href={`/${lang}/contact`}>
                    {content.buttonText}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionFive;
