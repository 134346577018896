import React from 'react';

const teamData = [
  {
    image: 'equipe_1',
    name: 'Jonathan AZOULAY',
    designation: 'CEO',
    description:
      'Porteur de projet reconnu pour ses talents de leadership et sa vaste expérience commerciale.'
  },
  {
    image: 'equipe_2',
    name: 'Côme PECORARI',
    designation: 'CTO',
    description:
      "Développeur vétéran passé de l'industriel embarqué au développement web fullstack."
  },
  {
    image: 'equipe_3',
    name: 'Victor BRUN',
    designation: 'Lead Développeur Blockchain',
    description:
      "Applications, Dapps et API avec un accent sur le traitement des données brutes, l'intelligence des données et la cybersécurité."
  },
  {
    image: 'equipe_4',
    name: 'Dr Tien Thao NGUYEN',
    designation: 'Responsable Optimization Logicielle',
    description:
      "Spécialisé dans l'architecture logicielle, l'optimisation de systèmes de calcul parrallèles et l'algorithmie."
  }
];
const teamDataEng = [
  {
    image: 'equipe_1',
    name: 'Jonathan AZOULAY',
    designation: 'CEO',
    description:
      'Established project manager with remarkable leadership skills and vast commercial experience.'
  },
  {
    image: 'equipe_2',
    name: 'Côme PECORARI',
    designation: 'CTO',
    description:
      'Veteran developer with experiences ranging from embedded industrial software to fullstack web development.'
  },
  {
    image: 'equipe_3',
    name: 'Victor BRUN',
    designation: 'Lead Blockchain Developer',
    description:
      'Apps, Dapps & APIs with a focus on raw data processing, data intelligence & cybersecurity.'
  },
  {
    image: 'equipe_4',
    name: 'Dr Tien Thao NGUYEN',
    designation: 'Software Optimization Manager',
    description:
      'Specialized in software architecture, optimization of parallel computing systems and algorithms.'
  }
];

const TeamTwo = ({ column, teamStyle }) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';
  const content = lang === 'fr' ? teamData : teamDataEng;

  return (
    <div className="row row--15">
      {content.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <div className="inner">
              <figure className="thumbnail">
                <img
                  src={`${process.env.PUBLIC_URL}/img/${data.image}.jpg`}
                  alt="Corporate React Template"
                />
              </figure>
              <figcaption className="content">
                <h2 className="title">{data.name}</h2>
                <h6 className="subtitle theme-gradient">{data.designation}</h6>

                <p className="description">{data.description}</p>
              </figcaption>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamTwo;
