import React from 'react';

const teamData = [
  {
    image: 'expert_1',
    name: 'Afsana',
    designation: 'Coordinateur Intelligence Data'
  },
  {
    image: 'expert_8',
    name: 'Jeremy',
    designation: 'Senior UX Designer'
  },
  {
    image: 'expert_3',
    name: 'Sophie',
    designation: 'Déléguée à la Protection des Données'
  },
  {
    image: 'expert_4',
    name: 'Antoine',
    designation: 'SEO Hacker'
  },
  {
    image: 'expert_5',
    name: 'Selim',
    designation: 'Chercheur en Cybersécurité Blockchain'
  },
  {
    image: 'expert_6',
    name: 'Heloïse',
    designation: 'Développeuse Applications Mobile'
  },
  {
    image: 'expert_7',
    name: 'Stéphane',
    designation: 'Architecte SaaS'
  },
  {
    image: 'expert_2',
    name: 'Léonard',
    designation: 'Ingénieur Cloud'
  }
];

const teamDataEng = [
  {
    image: 'expert_1',
    name: 'Afsana',
    designation: 'Data Intelligence Coordinator'
  },
  {
    image: 'expert_8',
    name: 'Jeremy',
    designation: 'Senior UX Designer'
  },
  {
    image: 'expert_3',
    name: 'Sophie',
    designation: 'Data Protection Officer'
  },
  {
    image: 'expert_4',
    name: 'Antoine',
    designation: 'SEO Hacker'
  },
  {
    image: 'expert_5',
    name: 'Selim',
    designation: 'Blockchain Cybersecurity Searcher'
  },
  {
    image: 'expert_6',
    name: 'Heloïse',
    designation: 'Mobile App Developer'
  },
  {
    image: 'expert_7',
    name: 'Stéphane',
    designation: 'SaaS Architect'
  },
  {
    image: 'expert_2',
    name: 'Léonard',
    designation: 'Cloud Engineer'
  }
];

const TeamThree = ({ column, teamStyle }) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <div className="row row--30">
      {(lang === 'fr' ? teamData : teamDataEng).map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <div className="inner" style={{ padding: 12 }}>
              <figure className="thumbnail">
                <img
                  src={`${process.env.PUBLIC_URL}/img/experts/${data.image}.jpg`}
                  alt="Corporate React Template"
                  style={{ width: '70%' }}
                />
              </figure>
              <figcaption className="content">
                <h2 className="title">{data.name}</h2>
                <h6 className="theme-gradient">{data.designation}</h6>
              </figcaption>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamThree;
