import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  if (lang === 'eng') {
    return (
      <ul className="mainmenu">
        <li>
          <Link to="/eng/capabilities">Capabilities</Link>
        </li>
        <li>
          <Link to="/eng/studio">Studio</Link>
        </li>
        <li>
          <Link to="/eng/contact">Contact</Link>
        </li>
      </ul>
    );
  } else {
    return (
      <ul className="mainmenu">
        <li>
          <Link to="/fr/competences">Compétences</Link>
        </li>
        <li>
          <Link to="/fr/agence">Agence</Link>
        </li>
        <li>
          <Link to="/fr/contact">Contact</Link>
        </li>
      </ul>
    );
  }
};
export default Nav;
