import React from 'react';
import Header from './header/Header';
import Footer from '../component/Footer';

const Layout = ({ children }) => {
  return (
    <>
      <main className="page-wrapper">
        <Header
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-not-transparent"
        />
        {children}
        <Footer />
      </main>
    </>
  );
};
export default Layout;
