import React from 'react';

const contentFr = [
  {
    image: '/img/services/smart-contract.jpg',
    title: 'Smart-Contracts',
    description:
      'Exploitez la puissance de la blockchain avec un backend reposant sur des smart-contracts économiques et sécurisés.'
  },
  {
    image: '/img/services/decentralized-app.jpg',
    title: 'Application Décentralisée',
    description:
      'Créez des applications accessibles, soignées et sécurisées autours de vos smart-contracts afin de rendre leur utilisation attractive.'
  },
  {
    image: '/img/services/token-and-nft.jpg',
    title: 'Token et NFT',
    description:
      'Lancez votre produit ou activité en utilisant le meilleur outil de participation décentralisée et authentifiée.'
  },
  {
    image: '/img/services/defi-robot.jpg',
    title: 'Robot Finance Décentralisée',
    description:
      "Automatisez des stratégies financières complexes dans l'écosystème de la DeFi grâce à de l'IA et des outils performants."
  },
  {
    image: '/img/services/api.jpg',
    title: 'API',
    description:
      "Développez un service de données ou offrez à vos clients la possibilité de s'interfacer à votre application."
  },
  {
    image: '/img/services/decentralized-hosting.jpg',
    title: 'Hébergement Décentralisé',
    description:
      'Basculez votre infrasctucture sur la blockchain ou IPFS afin de rendre vos services plus distribués, résilients et fiables.'
  },
  {
    image: '/img/services/seo.jpg',
    title: 'Positionnement SEO',
    description:
      'Offrez votre produit au plus grand nombre en positionant votre site parmi les premiers résultats des moteurs de recherches.'
  },
  {
    image: '/img/services/digital-marketing.jpg',
    title: 'Marketing Digital',
    description:
      'Boostez la portée de votre communication en animant une communautée engagée et fidèle à travers vos réseaux sociaux.'
  },
  {
    image: '/img/services/ui-ux.jpg',
    title: 'Refonte UX/UI',
    description:
      "Rendez l'utilisation de vos plateformes plus agréables et attirante en créant des interfaces ergonomiques."
  }
];

const contentEng = [
  {
    image: '/img/services/smart-contract.jpg',
    title: 'Smart-Contracts',
    description:
      'Harness the power of the blockchain with a cost-effective and secure smart-contracts based backend.'
  },
  {
    image: '/img/services/decentralized-app.jpg',
    title: 'Decentralized Application',
    description:
      'Create accessible, neat and secure apps to wrap your smart-contracts in simple and attractive interfaces.'
  },
  {
    image: '/img/services/token-and-nft.jpg',
    title: 'Token and NFT',
    description:
      'Launch your product or activity using the best tool for decentralized and authenticated participation.'
  },
  {
    image: '/img/services/defi-robot.jpg',
    title: 'Decentralized Finance Bot',
    description:
      'Automate complex financial strategies in the DeFi ecosystem with powerful AI and tools.'
  },
  {
    image: '/img/services/api.jpg',
    title: 'API',
    description:
      'Develop a data service or offer your customers the ability to interface with your application.'
  },
  {
    image: '/img/services/decentralized-hosting.jpg',
    title: 'Decentralized Hosting',
    description:
      'Switch your infrastructure to blockchain or IPFS to make your services more distributed, resilient and reliable.'
  },
  {
    image: '/img/services/seo.jpg',
    title: 'SEO Positioning',
    description:
      'Offer your product to as many people as possible by positioning your site among the first results of search engines.'
  },
  {
    image: '/img/services/digital-marketing.jpg',
    title: 'Digital Marketing',
    description:
      'Boost the reach of your communication with a committed and loyal community through your social networks.'
  },
  {
    image: '/img/services/ui-ux.jpg',
    title: 'UX/UI Redesign',
    description:
      'Make the use of your platforms more pleasant and attractive by creating ergonomic interfaces.'
  }
];

const ServiceFive = ({ textAlign, serviceStyle, longVersion, language }) => {
  return (
    <div className="row row--15 service-wrapper">
      {(language === 'fr' ? contentFr : contentEng)
        .slice(0, longVersion ? undefined : 3)
        .map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="content">
                  <h4
                    className="title theme-gradient"
                    style={{ minHeight: '50px' }}
                  >
                    {val.title}
                  </h4>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                    style={{ minHeight: '125px' }}
                  ></p>
                </div>
                <div className="image">
                  <img src={`${val.image}`} alt="card Images" />
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
export default ServiceFive;
