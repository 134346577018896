import React from 'react';

const contentFr = [
  {
    id: '1',
    title: 'Sonder le contexte de l’entreprise',
    description: 'Afin de révéler les fonctions pouvant bénéficier du digital'
  },
  {
    id: '2',
    title: 'Élaboration de solutions',
    description: 'Nous concevons avec vous une solution business adaptée.'
  },
  {
    id: '3',
    title: 'Des experts métier à chaque étape',
    description: 'Pas de solutions génériques, pour chaque métier son expert'
  },
  {
    id: '4',
    title: 'Suivi des facteurs clef de succès',
    description: 'Mesure l’incidence sur performance de l’entreprise'
  }
];
const contentEng = [
  {
    id: '1',
    title: 'Evaluate the context of the company',
    description: 'In order to reveal the functions that can benefit from tech'
  },
  {
    id: '2',
    title: 'Solutions Development',
    description: 'We design an optimal business solution.'
  },
  {
    id: '3',
    title: 'Business experts at every step',
    description: 'No generic answers, for each task its expert'
  },
  {
    id: '4',
    title: 'Follow-up of key success factors',
    description: 'Measures impact on business performance'
  }
];

const TimelineTwo = ({ classVar }) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';
  const content = lang === 'fr' ? contentFr : contentEng;

  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {content.map((data, index) => (
          <div
            className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <h6 className="title" style={{ height: '65px' }}>
                {data.title}
              </h6>

              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner"></div>
                </div>
              </div>

              <p className="description">{data.description}</p>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelineTwo;
