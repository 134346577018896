import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';
import PageScrollTop from './component/PageScrollTop';

// Pages import Here
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Error from './pages/Error';
import Home from './pages/Home';
import Legal from './pages/MentionsLegales';
import Rgpd from './pages/Rgpd';
import Services from './pages/Services';
//
import AboutUs_eng from './pages/eng/AboutUs';
import Contact_eng from './pages/eng/Contact';
import Home_eng from './pages/eng/Home';
import Legal_eng from './pages/eng/MentionsLegales';
import Rgpd_eng from './pages/eng/Rgpd';
import Services_eng from './pages/eng/Services';

// Import Css Here
import './assets/scss/style.scss';
import './css/index.css';

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route exact path="/">
            <Redirect to="/eng" />
          </Route>
          {
            // TO DEFAULT
          }
          <Route path="/agence">
            <Redirect to="/eng/studio" />
          </Route>
          <Route path="/contact">
            <Redirect to="/eng/contact" />
          </Route>
          <Route path="/competences">
            <Redirect to="/eng/capabilities" />
          </Route>
          <Route path="/rgpd">
            <Redirect to="/eng/gdpr" />
          </Route>
          <Route path="/legal">
            <Redirect to="/eng/tc" />
          </Route>
          <Route path="/erreur">
            <Redirect to="/eng/erreur" />
          </Route>
          {
            // FR
          }
          <Route exact path="/fr" component={Home} />
          <Route path="/fr/agence" component={AboutUs} />
          <Route path="/fr/contact" component={Contact} />
          <Route path="/fr/competences" component={Services} />
          <Route path="/fr/rgpd" component={Rgpd} />
          <Route path="/fr/legal" component={Legal} />
          <Route path="/fr/erreur" component={Error} />
          {
            // ENG
          }
          <Route exact path="/eng" component={Home_eng} />
          <Route path="/eng/studio" component={AboutUs_eng} />
          <Route path="/eng/contact" component={Contact_eng} />
          <Route path="/eng/capabilities" component={Services_eng} />
          <Route path="/eng/gdpr" component={Rgpd_eng} />
          <Route path="/eng/tc" component={Legal_eng} />
          <Route path="/eng/error" component={Error} />
          {
            // ANY
          }
          <Route component={Error} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
