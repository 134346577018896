import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { MdLocationPin } from 'react-icons/md';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function GoogleMap() {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  const defaultProps = {
    center: {
      lat: 48.81,
      lng: 2.38
    },
    zoom: 12,
    minZoom: 12,
    maxZoom: 12
  };

  return (
    // Important! Always set the container height explicitly
    <div className="google-map-style-1">
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAm8VKTYs2ADQj4PtZyrPx-ZkcptFNQ-a0',
            language: lang === 'fr' ? 'fr' : 'en'
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <MdLocationPin
            style={{
              height: '40px',
              width: '40px',
              color: 'red',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
            lat={48.8164}
            lng={2.3826}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
}
