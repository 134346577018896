import React from 'react';
import Header from '../../common/header/Header';
import SEO from '../../common/SEO';
import Footer from '../../component/Footer';

import CallActionContact from '../../component/CallActionContact';
import Services from '../../component/Services';
import SectionTitle from '../../elements/sectionTitle/SectionTitle';

const DigitalAgency = (props) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <>
      <SEO title="Capabilities || Inumeris" />
      <main className="page-wrapper">
        <Header btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="On today's menu"
                  title="What we do yields results."
                  description="The virtual world is an essential aspect of your business.<br/>This is why we support you in developing all the tools relevant to your business.<br/>No more long lists of technical appointments, at Inumeris you have a interlocutor who speaks to you in your terms."
                />
              </div>
            </div>
            <Services
              serviceStyle="gallery-style"
              textAlign="text-start"
              longVersion={true}
              language={lang}
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Call To Action Area  */}
        <div
          className="rwt-callto-action-area"
          style={{ marginBottom: '64px' }}
        >
          <div className="wrapper">
            <CallActionContact />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Footer />
      </main>
    </>
  );
};
export default DigitalAgency;
