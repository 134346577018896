import { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import Logo from '../../elements/logo/Logo';
import MobileMenu from './MobileMenu';
import Nav from './Nav';
import useStickyHeader from './useStickyHeader';
//
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';

const changePage = (page, currentLang, langTo) => {
  if (langTo === 'fr' && currentLang !== 'fr') {
    if (page.includes('/studio')) return '/fr/agence';
    else if (page.includes('/contact')) return '/fr/contact';
    else if (page.includes('/error')) return '/fr/erreur';
    else if (page.includes('/capabilities')) return '/fr/competences';
    else if (page.includes('/gdpr')) return '/fr/rgpd';
    else if (page.includes('/tc')) return '/fr/legal';
    else return '/fr';
  } else if (langTo === 'eng' && currentLang !== 'eng') {
    if (page.includes('/agence')) return '/eng/studio';
    else if (page.includes('/contact')) return '/eng/contact';
    else if (page.includes('/erreur')) return '/eng/error';
    else if (page.includes('/competences')) return '/eng/capabilities';
    else if (page.includes('/rgpd')) return '/eng/gdpr';
    else if (page.includes('/legal')) return '/eng/tc';
    else return '/eng';
  }
  return '';
};

const langList = [
  {
    code: 'eng',
    name: 'English'
  },
  {
    code: 'fr',
    name: 'Français'
  }
];

const Header = (props) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';
  const currentPage = window.location.href;

  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  const sticky = useStickyHeader(50);
  const stickyClass = sticky ? 'sticky' : '';

  return (
    <>
      <header
        className={`rn-header header-default header-left-align header-transparent ${stickyClass}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-12 position-static">
              <div className="header-left d-flex">
                <Logo
                  image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                  image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                />
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div style={{ alignSelf: 'center', marginLeft: 'auto' }}>
                  {
                    // LANG SELECT SAT
                  }
                  <Dropdown as={ButtonGroup}>
                    <Dropdown.Toggle
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        textAlign: 'center',
                        padding: '0',
                        boxShadow: 'none'
                      }}
                      id="dropdown-custom-1"
                    >
                      <span className="currentLang">
                        <img
                          src={require(`../../assets/images/lang/${lang}.png`)}
                          style={{ height: '32px' }}
                          alt="language"
                        />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ minWidth: '13rem' }}>
                      {(lang === 'fr'
                        ? [langList[1], langList[0]]
                        : langList
                      ).map((language, i) => (
                        <Dropdown.Item
                          key={i}
                          href={changePage(currentPage, lang, language.code)}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}
                          >
                            <img
                              src={require(`../../assets/images/lang/${language.code}.png`)}
                              style={{ height: '32px', paddingRight: '5px' }}
                              alt="language"
                            />
                            <div style={{ fontSize: '16px' }}>
                              {language.name}
                            </div>
                          </div>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {
                  // LANG SELECT END
                }
                <div className="header-right" style={{ paddingLeft: '1.5rem' }}>
                  <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                    <div className="hamberger">
                      <span
                        className="hamberger-button"
                        onClick={onCanvasHandler}
                      >
                        <FiMenu />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default Header;
