import React, { createRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link } from 'react-router-dom';

const Result = ({ language, result }) => {
  const innerContent =
    language === 'fr'
      ? 'Votre inscription à bien été prise en compte.'
      : 'Your registration has been taken into account.';
  return (
    <p className="success-message" style={result ? null : { display: 'none' }}>
      {innerContent}
    </p>
  );
};

const contentFr = {
  title: 'Restez à la page',
  text: 'Des rapport sur les dernières innovations qui vont faire vibrer vos clients et vos marchés.',
  buttonText: "JE M'INSCRIS",
  buttonTextSuccess: 'VOUS ÊTES INSCRIT ✔️'
};

const contentEng = {
  title: 'Stay up to date',
  text: 'Reports on the latest innovations that will thrill your customers and your markets.',
  buttonText: 'SUBSCRIBE',
  buttonTextSuccess: 'SUBSCRIBED ✔️'
};

const NewsletterOne = ({ extraClass, newsletetrStyle }) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  const content = lang === 'fr' ? contentFr : contentEng;

  const [result, showresult] = useState(false);
  const [form, setForm] = useState({
    email: '',
    recaptcha: ''
  });

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const result = await fetch(
        `${process.env.PUBLIC_URL}/.netlify/functions/fn_contact`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: JSON.stringify({ isNewsletter: true, ...form })
        }
      );

      recaptchaRef.current.reset();

      if (result.status === 200) {
        e.target.reset();
        showresult(true);
      } else {
        throw Error(
          lang === 'fr'
            ? "Désolé, nous n'avons pas pu prendre en compte votre inscription. Veuillez réessayer plus tard."
            : 'Sorry we could not subscribe you to our newsletter. Please try again later.'
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  const recaptchaRef = createRef();

  const handleRecaptcha = (value) => {
    const newState = { ...form, recaptcha: value };
    setForm(newState);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const field = e.target.name;
    const newState = { ...form, [field]: value };
    setForm(newState);
  };

  return (
    <div className={`newsletter-area ${newsletetrStyle}`}>
      <div className="container">
        <div
          className={`row row--0 newsletter-wrapper align-items-center ${extraClass}`}
        >
          <div className="col-lg-4">
            <div className="newsletter-section-title">
              <h3 className="title">{content.title}</h3>
              <p className="description">{content.text}</p>
            </div>
            <div
              className="form-group"
              style={{
                marginTop: '4rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around'
              }}
            >
              <a href="https://twitter.com" target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.PUBLIC_URL}/img/social/twitter.png`}
                  style={{ height: '40px' }}
                  alt="telegram"
                />
              </a>
              <a href="https://telegram.org/" target="_blank" rel="noreferrer">
                <img
                  src={`${process.env.PUBLIC_URL}/img/social/telegram.png`}
                  style={{ height: '40px' }}
                  alt="telegram"
                />
              </a>
              <a
                href="https://www.linkedin.com"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/img/social/linkedin.png`}
                  style={{ height: '40px' }}
                  alt="telegram"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-8">
            <form
              className="rn-newsletter mt_md--20 mt_sm--20"
              onSubmit={handleSubmit}
            >
              <div
                className="form-group"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-evenly'
                }}
              >
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  required
                  style={{ marginTop: '2rem', maxWidth: '200px' }}
                />

                <ReCAPTCHA
                  sitekey="6LdK9PYhAAAAAPZuKpiP1qILdK-lf8MiCrGtuuZN"
                  theme="dark"
                  ref={recaptchaRef}
                  onChange={handleRecaptcha}
                  hl={lang === 'fr' ? 'fr' : 'en'}
                  style={{
                    marginLeft: '2rem',
                    marginTop: '2rem',
                    transform: 'scale(0.90)'
                  }}
                />

                <button
                  className={result ? 'btn-default btn-green' : 'btn-default'}
                  style={{ marginLeft: '2rem', marginTop: '2rem' }}
                >
                  {result ? content.buttonTextSuccess : content.buttonText}
                </button>
              </div>
            </form>
            <Result language={lang} result={result} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsletterOne;
