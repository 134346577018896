import React from 'react';
import Header from '../common/header/Header';
import SEO from '../common/SEO';
import Footer from '../component/Footer';

import CallActionContact from '../component/CallActionContact';
import Services from '../component/Services';
import SectionTitle from '../elements/sectionTitle/SectionTitle';

const DigitalAgency = (props) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <>
      <SEO title="Compétences || Inumeris" />
      <main className="page-wrapper">
        <Header btnStyle="btn-small" HeaderSTyle="header-transparent" />

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Au menu du jour"
                  title="Ce que nous faisons obtient des résultats."
                  description="Le digital est une facette incontournable de votre entreprise.<br/>C'est pourquoi nous vous accompagnons pour développer l'ensemble des segments pertinents à votre activité.<br/>Fini les longues listes de RDV techniques, chez Inumeris vous avez un interlocuteur qui vous parle en vos termes."
                />
              </div>
            </div>
            <Services
              serviceStyle="gallery-style"
              textAlign="text-start"
              longVersion={true}
              language={lang}
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Call To Action Area  */}
        <div
          className="rwt-callto-action-area"
          style={{ marginBottom: '64px' }}
        >
          <div className="wrapper">
            <CallActionContact />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Footer />
      </main>
    </>
  );
};
export default DigitalAgency;
