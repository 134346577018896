import React from 'react';
import Header from '../../common/header/Header';
import SEO from '../../common/SEO';
import CallActionContact from '../../component/CallActionContact';
import Equipe from '../../component/Equipe';
import Experts from '../../component/Experts';
import Footer from '../../component/Footer';
import Valeurs from '../../component/Valeurs';
import SectionTitle from '../../elements/sectionTitle/SectionTitle';

const AboutUs = (props) => {
  return (
    <>
      <SEO title="Studio || Inumeris" />
      <main className="page-wrapper">
        <Header
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Start Service Area  */}
        <div className="service-area rn-section-gapTop">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="content">
                  <h3 className="title">Who we are</h3>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb--10">
                  Inumeris is a united team made of creative and curious members
                  whose head office is based in Paris.
                  <br />
                  <br /> We are open, collaborative and leverage our collective
                  experience and technical expertise in order to create digital
                  solutions on any type support.
                  <br />
                  <br /> We embrace new challenges with an open heart, a desire
                  to learn and a passion for collaborative digital problem
                  solving.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Grow the digital world without becoming robots"
                  title="Our values"
                />
              </div>
            </div>
            <Valeurs
              serviceStyle="service__style--1 bg-color-blackest radius mt--25"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="tech lovers"
                  title="Permanent Team"
                  description="You don't speak tech ? Perfect. We want to talk to you about business."
                />
              </div>
            </div>
            <Equipe
              column="col-lg-6 col-md-6 col-12 mt--10"
              teamStyle="team-style-default style-two"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb--15">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="a generalist < an expert"
                  title="Our Experts"
                  description="With us, each trade has its own dedicated expert."
                />
              </div>
            </div>
            <Experts
              column="col-lg-3 col-md-6 col-12 mt--30"
              teamStyle="team-style-two"
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Call To Action Area  */}
        <div
          className="rwt-callto-action-area"
          style={{ marginBottom: '64px' }}
        >
          <div className="wrapper">
            <CallActionContact />
          </div>
        </div>
        {/* End Call To Action Area  */}

        <Footer />
      </main>
    </>
  );
};

export default AboutUs;
