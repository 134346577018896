import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ image, image2 }) => {
  const lang = window.location.href.includes('/fr') ? 'fr' : 'eng';

  return (
    <div className="logo">
      <Link to={process.env.PUBLIC_URL + `/${lang}`}>
        <img
          className="logo-light"
          src={process.env.PUBLIC_URL + image}
          alt="Corporate Logo"
        />
        <img
          className="logo-dark"
          src={process.env.PUBLIC_URL + image2}
          alt="Corporate Logo"
        />
      </Link>
    </div>
  );
};
Logo.propTypes = {
  image: PropTypes.string
};

export default Logo;
