import PropTypes from 'prop-types';
import React from 'react';

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="robots" content="follow" />
      <meta
        name="description"
        content="Inumeris est une agence numérique qui offre des services de marketing digital, de développement web, de stratégie digitale et d'IA pour les marques et les entreprises."
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta property="og:title" content="Inumeris - Agence Digitale" />
      <meta
        property="og:description"
        content="Inumeris est une agence numérique qui offre des services de marketing digital, de développement web, de stratégie digitale et d'IA pour les marques et les entreprises."
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.inumeris.fr" />
      <meta
        property="og:image"
        content="https://www.inumeris.fr/img/og-image.jpg"
      />
      <link
        rel="prefetch"
        href={`${process.env.PUBLIC_URL}/img/lang/eng.png`}
      />
      <link rel="prefetch" href={`${process.env.PUBLIC_URL}/img/lang/fr.png`} />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string
};

export default SEO;
